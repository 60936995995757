import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { OrganizationsContextProvider } from "./context/OrganizationsContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <OrganizationsContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </OrganizationsContextProvider>
    </DarkModeContextProvider>
  </React.StrictMode>
);
