// Define the OrganizationsReducer
const OrganizationsReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORGANIZATIONS": {
      // Set organizations in state
      const organizations = action.payload;
      
      // Set selected organization to the first organization by default
      const selected = organizations.length > 0 ? organizations[0] : null;

      // Update localStorage with organizations object
      //localStorage.setItem("organizations", JSON.stringify({ organizations, selected }));

      return {
        ...state,
        organizations,
        selected
      };
    }
    case "SELECT_ORGANIZATION": {
      const { name, id } = action.payload;
      return {
        ...state,
        selected: { name, id }
      };
    }
    case "LOGOUT": {
      return {
        state:null
      };
    }
    case "ADD_ORGANIZATION": {
      const newOrganization = action.payload;
      const updatedOrganizations = [...state.organizations, newOrganization];
      return {
        ...state,
        organizations: updatedOrganizations,
      };
    }
    default:
      return state;
  }
};
export default OrganizationsReducer;
