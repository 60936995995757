//MASS amount in g.
export const MASS_UNITS = [
    {
        weight_unit_name: 'pounds',
        abbreviation: 'lb',
        amount: 453.59237
        //453.5924
        //453.59237
    },
    {
        weight_unit_name: 'grams',
        abbreviation: 'g',
        amount: 1
        //1
    },
    {
        weight_unit_name: 'kilograms',
        abbreviation: 'kg',
        amount: 1000
        //1000
    },
    {
        weight_unit_name: 'milligrams',
        abbreviation: 'mg',
        amount: 0.001
        //0.001
    },

    // Add more units and amounts as needed
];

//Volume amount in mL
export const VOLUME_UNITS = [
    {
        volume_unit_name: 'milliliters',
        abbreviation: 'mL',
        amount: 1
        //1
    },
    {
        volume_unit_name: 'liters',
        abbreviation: 'L',
        amount: 1000
        //1000
    },
    {
        volume_unit_name: 'gallon',
        abbreviation: 'gal',
        amount: 3785.41
        //3785.41
    },
    {
        volume_unit_name: 'teaspoons',
        abbreviation: 'tsp',
        amount: 5.91939
        //5.91939
    }
    // Add more units and amounts as needed
];
