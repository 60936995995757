import { useContext, useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

const Register = ({ setShowRegister }) => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try{
      const res = await createUserWithEmailAndPassword(auth, email, password)
      const user = res.user;
      await setDoc(doc(db, "users", res.user.uid), {
        email,
        "organizationsId":[],
        timeStamp: serverTimestamp()
      });
      dispatch({ type: "LOGIN", payload: user });
      navigate("/");
    } catch(err){
      console.log(err)
    }
  };

  const handleBackClick = () => {
    setShowRegister(false);
  };

  return (
    <div className="register-container">
      <form onSubmit={handleRegister}>
        <h2>Register</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Register</button>
        <button type="button" onClick={handleBackClick}>Back to Login</button>
        {error && <span className="error">{error}</span>}
      </form>
    </div>
  );
};

export default Register;
