import React from 'react';
import { MASS_UNITS } from "../../constants";
import "./printableComponent.css";

const SummaryPrintableComponent = React.forwardRef(({ summaryData, day, date, generateMixerChartTable }, ref) => {
  return (
    <div ref={ref} className="printableComponent printable-container">
      <h3 style={{ display: 'inline', marginRight: '10px' }}>Production Summary</h3> {day} {date}
      {summaryData.map((list, listIndex) => (
        <div key={listIndex}>
          <p>Production List: {list.listName}</p>
          {list.lines.map((line, lineIndex) => (
            <div key={lineIndex}>
              <strong>Line: {line.lineName}</strong>
              {line.products.map((product, productIndex) => (
                <div key={productIndex}>
                  <p>
                    {product.productName}, Qty: {product.quantity} {product.unitName}
                  </p>
                  <ul>
                    {product.ingredientDetails.map((ingredient, ingredientIndex) => {
                      const fullMixes = Math.floor(ingredient.mixes);
                      const remainingMix = ingredient.mixes - fullMixes;
                      const remainingMixValue = remainingMix > 0
                        ? Math.max(ingredient.mixMin, remainingMix * ingredient.mixMax)
                        : 0;
                      return (
                        <React.Fragment key={ingredientIndex}>
                          {/* <li>
                            {ingredient.ingredientName}: {ingredient.total}, {ingredient.scale}, {ingredient.scrap}
                            {ingredient.mixes > 0 && (
                              <> , {ingredient.mixes} mixes @ {ingredient.mixMax / MASS_UNITS[0].amount + MASS_UNITS[0].abbreviation} </>
                            )}
                          </li> */}
                          <div className="mix-grid">
                            {/* Full mixes */}
                            {Array.from({ length: fullMixes }, (_, i) => (
                              <div key={i} className="mix-item">
                                <span>
                                  {ingredient.mixMax / MASS_UNITS[0].amount} {MASS_UNITS[0].abbreviation}
                                </span>
                              </div>
                            ))}
                            {/* Remaining mix if any */}
                            {remainingMix > 0 && (
                              <div className="mix-item">
                                <span>
                                  {Math.ceil(remainingMixValue / MASS_UNITS[0].amount)} {MASS_UNITS[0].abbreviation}
                                </span>
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
      <div className="page-break"></div>
      
      {generateMixerChartTable()}

    </div>
  );
});

export default SummaryPrintableComponent;
