import { createContext, useEffect, useReducer } from "react";
import OrganizationsReducer from "./OrganizationsReducer";

const INITIAL_STATE = JSON.parse(localStorage.getItem("organizations")) || {
  organizations: null,
  selected: null,
};


export const OrganizationsContext = createContext(INITIAL_STATE);

export const OrganizationsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrganizationsReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("organizations", JSON.stringify(state));
  }, [state]);

  return (
    <OrganizationsContext.Provider value={{ organizations: state.organizations, selected: state.selected, dispatch }}>
      {children}
    </OrganizationsContext.Provider>
  );
};
