export const ingredientInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Flour",
  },
  {
    id: "manufacturerCode",
    label: "Manufacturer Code",
    type: "text",
    placeholder: "Manufacturer Code",
  },
  {
    id: "volume",
    label: "Volume Ingredient",
    type: "checkbox",
  },
];

export const ingredientVolumeInputs = [
  {
    id: "hydration",
    label: "Hydration (%)",
    type: "number",
    placeholder: "80%",
  },
  {
    id: "massconversion",
    label: "Mass/Volume (g/mL)",
    type: "number",
    placeholder: "1.04",
  },


];

export const doughInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "French",
  },
  {
    id: "manufacturerCode",
    label: "Manufacturer Code",
    type: "text",
    placeholder: "Manufacturer Code",
  },
  // {
  //   id: "minMix",
  //   label: "Min Mix",
  //   type: "number",
  //   placeholder: "Minimum Weight for Mix",
  //   unitOptions: true,
  // },
  // {
  //   id: "maxMix",
  //   label: "Max Mix",
  //   type: "number",
  //   placeholder: "Minimum Weight for Mix",
  //   unitOptions: true,
  // },
  {
    id: "mixTime",
    label: "Mix Time",
    type: "number",
    placeholder: "Time of mix (minutes)",
  },
  {
    id: "mixTime2",
    label: "Second Mix Time",
    type: "number",
    placeholder: "Time of mix (minutes)",
  },
  {
    id: "fermentTime",
    label: "Fermentation Time",
    type: "number",
    placeholder: "Time of fermentation (minutes)",
  },
];

export const productInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Hero",
  },
  {
    id: "manufacturerCode",
    label: "Manufacturer Code",
    type: "text",
    placeholder: "Manufacturer Code",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  // {
  //   id: "price",
  //   label: "Price",
  //   type: "number",
  //   placeholder: "1.00",
  // },
];

export const lineInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Fancy Line Name",
  },
];

export const productionListInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Morning Production",
  },
];

export const userInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "displayName",
    label: "Name and surname",
    type: "text",
    placeholder: "John Doe",
  },
  {
    id: "email",
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "USA",
  },
];
