import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  ingredientInputs,
  doughInputs,
  productInputs,
  userInputs,
  ingredientVolumeInputs, // Import volume inputs
  lineInputs,
  productionListInputs,
} from "../../formSource";

import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const New = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [doughs, setDoughs] = useState([]);
  const [products, setProducts] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  //RECIPE STATE INFORMATION
  const [ingredientAmounts, setIngredientAmounts] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [conversionRates, setConversionRates] = useState([]);

  const [minMixUnit, setMinMixUnit] = useState(MASS_UNITS[0])
  const [maxMixUnit, setMaxMixUnit] = useState(MASS_UNITS[0])
  //Product Units
  const [productUnits, setProductUnits] = useState([]);

  //PRODUCTION LINES STATE INFORMATION
  const [productsPercentage, setProductsPercentage] = useState([]);
  //PRODUCTION LISTS STATE INFORMATION
  const [linesAndProducts, setLinesAndProducts] = useState([]);

  const navigate = useNavigate();
  const { type } = useParams();
  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const formConfig = {
    ingredients: { inputs: ingredientInputs, title: "Add New Ingredient Form" },
    doughs: { inputs: doughInputs, title: "Add New Doughs Form" },
    products: { inputs: productInputs, title: "Add New Product Form" },
    users: { inputs: userInputs, title: "Add New User Form" },
    lines: { inputs: lineInputs, title: "Add New Production Line Form" },
    productionlists: {
      inputs: productionListInputs,
      title: "Add New Production List Form",
    },
  };

  const fetchItems = (collectionName, stateUpdater) => {
    return onSnapshot(
      query(
        collection(db, collectionName),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          collection: collectionName,
          ...doc.data(),
        }));
        stateUpdater(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const unsubscribeIngredients = fetchItems("ingredients", setIngredients);
    const unsubscribeDoughs = fetchItems("doughs", setDoughs);
    const unsubscribeProducts = fetchItems("products", setProducts);
    const unsubscribeProductionLines = fetchItems("lines", setProductionLines);
    return () => {
      unsubscribeIngredients();
      unsubscribeDoughs();
      unsubscribeProducts();
      unsubscribeProductionLines();
    };
  }, [organizationId]);

  const allItems = [...ingredients, ...doughs, ...products];

  const handleInput = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === "checkbox") {
      setData({ ...data, [id]: checked });
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleSelectChange = (e, index, stateUpdater) => {
    const { value } = e.target;
    const selectedIngredient = allItems.find(
      (ingredient) => ingredient.id === value
    );
    const newIngredientAmounts = [...stateUpdater];
    newIngredientAmounts[index] = {
      id: value,
      amount: "",
      collection: selectedIngredient.collection,
    };
    setIngredientAmounts(newIngredientAmounts);

    const isVolume = selectedIngredient.volume
      ? selectedIngredient.volume
      : false;
    if (isVolume) {
      const newConversionRates = [...conversionRates];
      newConversionRates[index] = selectedIngredient.massconversion;
      setConversionRates(newConversionRates);
    }
    const defaultUnit = isVolume ? VOLUME_UNITS[0] : MASS_UNITS[0];

    const newSelectedUnits = [...selectedUnits];
    newSelectedUnits[index] = { unit: defaultUnit, isVolume };
    setSelectedUnits(newSelectedUnits);
  };

  const handleAddIngredient = () => {
    setIngredientAmounts([
      ...ingredientAmounts,
      { id: "", amount: "", collection: "" },
    ]);
  };

  const handleRemoveItem = (index) => {
    const newIngredientAmounts = [...ingredientAmounts];
    newIngredientAmounts.splice(index, 1);
    setIngredientAmounts(newIngredientAmounts);
    const newSelectedUnits = [...selectedUnits];
    newSelectedUnits.splice(index, 1);
    setSelectedUnits(newSelectedUnits);
    const newConversionRates = [...conversionRates];
    newConversionRates.splice(index, 1);
    setConversionRates(newConversionRates);
  };

  const handleUnitChange = (e, index) => {
    const { value } = e.target;

    let isVolume = false;
    let unit = VOLUME_UNITS.find((unit) => unit.abbreviation === value);

    if (!unit) {
      unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
      isVolume = false;
    } else {
      isVolume = true;
    }

    if (unit) {
      const newSelectedUnits = [...selectedUnits];
      newSelectedUnits[index] = { unit, isVolume };
      setSelectedUnits(newSelectedUnits);
    }
  };

  const handleMixUnitChange = (e) => {
    const { value, id } = e.target;
    console.log("id ", id)
    const unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
    if (id === "minMix") {
      setMinMixUnit(unit);
    } else {
      setMaxMixUnit(unit)
    }
  };
  const handleMixInput = (e) => {
    let mixValue = 0
    const { id, value } = e.target;
    if (id === "minMix") {
      mixValue = value * minMixUnit.amount
    } else {
      mixValue = value * maxMixUnit.amount
    }

    setData({ ...data, [id]: mixValue });

  };


  const handleAddProduct = () => {
    setProductsPercentage([
      ...productsPercentage,
      { id: "", scrapPercent: "", piecesPerHour: "" },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage.splice(index, 1);
    setProductsPercentage(newProductsPercentage);
  };

  const handleProductUnitChange = (e, index, field) => {
    const newUnits = [...productUnits];
    newUnits[index][field] = e.target.value;
    setProductUnits(newUnits);
  };

  const handleAddUnit = () => {
    setProductUnits([...productUnits, { name: "", pieceCount: "" }]);
  };

  const handleRemoveUnit = (index) => {
    const newUnits = [...productUnits];
    newUnits.splice(index, 1);
    setProductUnits(newUnits);
  };


  const handleProductSelectChange = (e, index) => {
    const { value } = e.target;
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage[index].id = value;
    setProductsPercentage(newProductsPercentage);
  };

  const handleProductPercentageChange = (e, index) => {
    const { name, value } = e.target;
    const newProductsPercentage = [...productsPercentage];
    newProductsPercentage[index][name] = value;
    setProductsPercentage(newProductsPercentage);
  };

  const handleAddLine = () => {
    // setLinesAndProducts([...linesAndProducts, { id: "", products: [] }]);
    setLinesAndProducts((prevData) => [...prevData, { id: "", products: [] }]);
  };

  const handleAddProductToLine = (index) => {
    console.log("inside handleAddProductToLine");
    console.log(
      "linesAndProducts ",
      linesAndProducts,
      linesAndProducts[index].products
    );
    let newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[index].products.push("");
    console.log(newLinesAndProducts);

    setLinesAndProducts(newLinesAndProducts);
  };

  const handleRemoveProductFromLine = (lineIndex, productIndex) => {
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[lineIndex].products.splice(productIndex, 1);
    setLinesAndProducts(newLinesAndProducts);
  };

  const handleRemoveLine = (index) => {
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts.splice(index, 1);
    setLinesAndProducts(newLinesAndProducts);
  };
  const handleLineSelectChange = (e, index) => {
    const { value } = e.target;
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[index].id = value;
    setLinesAndProducts(newLinesAndProducts);
  };
  const handleLineProductSelectChange = (e, lineIndex, productIndex) => {
    const { value } = e.target;
    const newLinesAndProducts = [...linesAndProducts];
    newLinesAndProducts[lineIndex].products[productIndex] = value;
    setLinesAndProducts(newLinesAndProducts);
  };

  function getItemNameByProductRef(productRef) {
    const [collection, id] = productRef.split("/");

    const item = allItems.find(
      (item) => item.collection === collection && item.id === id
    );

    return item ? item.name : "Item not found";
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      let newData = {
        organizationId: organizationId,
      };
      if (type === "ingredients" || type === "doughs" || type === "products") {
        newData = {
          ...newData,
          ...data,
          ingredients: ingredientAmounts.map(({ id, amount, collection }) => ({
            [`${collection}/${id}`]: amount,
            //ingredientRef: doc(db, collection, id),

            //amount: amount,
          })),
          ...(type === "products" && { productUnits }),
        };
      } else if (type === "lines") {
        newData = {
          ...newData,
          ...data,
          products: productsPercentage.map((product) => ({
            [`products/${product.id}`]: {
              piecesPerHour: product.piecesPerHour,
              scrapPercent: product.scrapPercent,
            },
          })),
        };
      } else if (linesAndProducts.length > 0 && type === "productionlists") {
        newData = {
          ...newData,
          ...data,
          // linesAndProducts: linesAndProducts,
          linesAndProducts: linesAndProducts.map((line) => ({
            [`lines/${line.id}`]: [...line.products]
          }))
        };
      } else {
        newData = {
          ...data,
        };
      }

      if (type === "users") {
        const res = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        await setDoc(doc(db, "users", res.user.uid), {
          ...data,
          timeStamp: serverTimestamp(),
        });
      } else {
        const newItemRef = doc(collection(db, type));
        await setDoc(newItemRef, newData);
      }
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      {console.log("newData ", data)}
      {/* {console.log("allItems ", allItems)} */}
      {/* {console.log("ingredientAmounts ", ingredientAmounts)}
      
      {console.log("conversionRates ", conversionRates)} */}
      {/* {console.log("productionLines ", productionLines)} */}
      {/* {console.log("productsPercentage ", productsPercentage)} */}
      {/* {console.log("selectedUnits ", selectedUnits)} */}
      {/* {console.log("linesAndProducts ", linesAndProducts)} */}
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{formConfig[type]?.title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {formConfig[type]?.inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}
              {type === "doughs" && (<>
                <div className="formInput">
                  <label >Min Mix </label>
                  <input
                    id="minMix"
                    type="Number"
                    placeholder="0"
                    onChange={handleMixInput}
                  />
                  <select
                    id="minMix"
                    onChange={(e) => {
                      console.log("unit select");
                      handleMixUnitChange(e);
                    }}
                  >
                    {MASS_UNITS.map((unit, index) => (
                      <option
                        key={index}
                        value={unit.abbreviation}
                      >
                        {unit.abbreviation}
                      </option>
                    ))}
                  </select>
                  </div>
                  <div className="formInput">

                  <label>Max Mix </label>
                  <input
                    id="maxMix"
                    type="Number"
                    placeholder="0"
                    onChange={handleMixInput}
                  />
                  <select
                    id="maxMix"
                    onChange={(e) => {
                      handleMixUnitChange(e);
                    }}
                  >
                    {MASS_UNITS.map((unit, index) => (
                      <option
                        key={index}
                        value={unit.abbreviation}
                      >
                        {unit.abbreviation}
                      </option>
                    ))}
                  </select>
                </div>
                </>
              )}
              {data.volume ? (
                <>
                  {ingredientVolumeInputs.map((input) => (
                    <div className="formInput" key={input.id}>
                      <label htmlFor={input.id}>{input.label}</label>
                      <input
                        id={input.id}
                        type={input.type}
                        step="any"
                        placeholder={input.placeholder}
                        onChange={handleInput}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <br />
                  {(type === "ingredients" ||
                    type === "doughs" ||
                    type === "products") && (<>
                      <div>
                        <h3>Ingredients:</h3>

                        {ingredientAmounts.map((ingredientAmount, index) => (
                          <div key={index}>
                            <select
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  index,
                                  ingredientAmounts,
                                  allItems.find(
                                    (ingredient) =>
                                      ingredient.id === e.target.value
                                  )
                                )
                              }
                              value={ingredientAmount.id}
                            >
                              <option value="">Select Ingredient</option>
                              {allItems.map((ingredient, index) => (
                                <option key={index} value={ingredient.id}>
                                  {ingredient.name} ({ingredient.collection})
                                </option>
                              ))}
                            </select>

                            {ingredientAmount.id !== "" ? (
                              <>
                                <input
                                  type="number"
                                  value={
                                    selectedUnits[index].isVolume
                                      ? ingredientAmount.amount /
                                      selectedUnits[index].unit.amount /
                                      conversionRates[index]
                                      : ingredientAmount.amount /
                                      selectedUnits[index].unit.amount
                                  }
                                  onChange={(e) => {
                                    const newIngredientAmounts = [
                                      ...ingredientAmounts,
                                    ];
                                    if (selectedUnits[index].isVolume) {
                                      newIngredientAmounts[index].amount =
                                        e.target.value *
                                        selectedUnits[index].unit.amount *
                                        conversionRates[index];
                                    } else {
                                      newIngredientAmounts[index].amount =
                                        e.target.value *
                                        selectedUnits[index].unit.amount;
                                    }

                                    setIngredientAmounts(newIngredientAmounts);
                                  }}
                                  placeholder="Amount"
                                />{" "}
                                {allItems.find(
                                  (item) => item.id === ingredientAmount.id
                                ).volume ? (
                                  <>
                                    <select
                                      onChange={(e) => {
                                        console.log("unit select");
                                        handleUnitChange(e, index);
                                      }}
                                    >
                                      {VOLUME_UNITS.map((unit, index) => (
                                        <option
                                          key={index}
                                          value={unit.abbreviation}
                                        >
                                          {unit.abbreviation}
                                        </option>
                                      ))}
                                      {MASS_UNITS.map((unit, index) => (
                                        <option
                                          key={index}
                                          value={unit.abbreviation}
                                        >
                                          {unit.abbreviation}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <select
                                      onChange={(e) => {
                                        console.log("unit select");
                                        handleUnitChange(e, index);
                                      }}
                                    >
                                      {MASS_UNITS.map((unit, index) => (
                                        <option
                                          key={index}
                                          value={unit.abbreviation}
                                        >
                                          {unit.abbreviation}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                )}{" "}
                              </>
                            ) : (
                              <>{"  "}</>
                            )}
                            <button
                              type="button"
                              onClick={() => handleRemoveItem(index)}
                            >
                              Remove
                            </button>
                          </div>
                        ))}

                        <button type="button" onClick={handleAddIngredient}>
                          Add Ingredient
                        </button>
                      </div>

                      {type === "products" && (
                        <div>
                          <h3>Units:</h3>
                          {productUnits.map((unit, index) => (
                            <div key={index}>
                              <input
                                type="text"
                                value={unit.name}
                                placeholder="Unit Name"
                                onChange={(e) => handleProductUnitChange(e, index, "name")}
                              />
                              <input
                                type="number"
                                value={unit.pieceCount}
                                placeholder="Piece Count"
                                onChange={(e) => handleProductUnitChange(e, index, "pieceCount")}
                              />
                              <button type="button" onClick={() => handleRemoveUnit(index)}>
                                Remove
                              </button>
                            </div>
                          ))}
                          <button type="button" onClick={handleAddUnit}>
                            Add Unit
                          </button>
                        </div>
                      )}
                    </>
                    )}
                </>
              )}

              {type === "lines" ? (
                <div>
                  <h3>Products run on line:</h3>
                  {productsPercentage.length > 0 ? (
                    <>
                      <table>
                        <th>Product </th>
                        <th>Pieces/Hour </th>
                        <th>Scrap % </th>

                        {productsPercentage.map((product, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                onChange={(e) =>
                                  handleProductSelectChange(e, index)
                                }
                                value={product.id}
                              >
                                <option value="">Select Product</option>
                                {products.map((product, index) => (
                                  <option key={index} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            {productsPercentage[index].id !== "" ? (
                              <>
                                <td>
                                  <input
                                    type="number"
                                    name="piecesPerHour"
                                    value={product.piecesPerHour}
                                    onChange={(e) =>
                                      handleProductPercentageChange(e, index)
                                    }
                                    placeholder="Pieces/Hour"
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="scrapPercent"
                                    value={product.scrapPercent}
                                    onChange={(e) =>
                                      handleProductPercentageChange(e, index)
                                    }
                                    placeholder="Scrap Percentage"
                                  />
                                </td>
                              </>
                            ) : null}
                            <button
                              type="button"
                              onClick={() => handleRemoveProduct(index)}
                            >
                              Remove
                            </button>
                          </tr>
                        ))}
                      </table>
                    </>
                  ) : null}
                  <button type="button" onClick={handleAddProduct}>
                    Add Product
                  </button>
                </div>
              ) : null}

              {type === "productionlists" ? (
                <div>
                  <h3>Production Lines and Products</h3>
                  {linesAndProducts.map((line, lineIndex) => (
                    <div key={lineIndex}>
                      Production Line {lineIndex + 1}
                      <br />
                      <select
                        onChange={(e) => handleLineSelectChange(e, lineIndex)}
                        value={line.id}
                      >
                        <option value="">Select Production Line</option>
                        {productionLines.map((line, optionsIndex) => (
                          <option key={optionsIndex} value={line.id}>
                            {line.name}
                          </option>
                        ))}
                      </select>
                      {linesAndProducts[lineIndex].id !== "" ? (
                        <>
                          <button
                            type="button"
                            onClick={() => handleAddProductToLine(lineIndex)}
                          >
                            Add Product to Line
                          </button>{" "}
                        </>
                      ) : null}{" "}
                      <button
                        type="button"
                        onClick={() => handleRemoveLine(lineIndex)}
                      >
                        Remove
                      </button>
                      <br />
                      {linesAndProducts[lineIndex].id !== "" ? (
                        <>
                          {linesAndProducts[lineIndex].products.map(
                            (product, productIndex) => (
                              <div key={productIndex}>
                                <ul>
                                  Product {productIndex + 1} <br />
                                  <select
                                    onChange={(e) => {
                                      console.log(
                                        "handle change in ",
                                        lineIndex,
                                        " ",
                                        productIndex
                                      );

                                      handleLineProductSelectChange(
                                        e,
                                        lineIndex,
                                        productIndex
                                      );
                                    }}
                                    value={
                                      linesAndProducts[lineIndex].products[
                                      productIndex
                                      ]
                                    }
                                  >
                                    <option value="" key="initial">
                                      Select{" "}
                                    </option>
                                    {productionLines
                                      .find(
                                        (line) =>
                                          line.id ===
                                          linesAndProducts[lineIndex].id
                                      )
                                      .products.map(
                                        (product, productOptionIndex) => {
                                          const productRef =
                                            Object.keys(product)[0];
                                          return (
                                            <option
                                              value={productRef}
                                              key={productOptionIndex}
                                            >
                                              {getItemNameByProductRef(
                                                productRef
                                              )}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>

                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveProductFromLine(
                                        lineIndex,
                                        productIndex
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </ul>
                              </div>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  ))}
                  <button type="button" onClick={handleAddLine}>
                    Add Production Line
                  </button>
                </div>
              ) : null}

              <div>
                <button disabled={per !== null && per < 100} type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
