import { useContext, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase"; // Import db from firebase
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import Register from "./Register";

import {
  getDoc,
  doc,
} from "firebase/firestore";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showRegister, setShowRegister] = useState(false);

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);
  const {dispatch: dispatchOrganizations }= useContext(OrganizationsContext)

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user ", user)
        dispatch({ type: "LOGIN", payload: user });

        // After successful login, fetch user organizations
        //fetchUserOrganizations(user.uid);
        const organizations = await fetchOrganizations(user.uid)
        console.log("organizations ", organizations)
        dispatchOrganizations({ type: "SET_ORGANIZATIONS", payload: organizations})
        navigate("/");
      })
      .catch((error) => {
        setError(true);
      });
  };

  const fetchOrganizations = async (uid) => {
    console.log("calling fetch")
    try {
      const userDocRef = doc(db, "users", uid);
      const userSnapshot = await getDoc(userDocRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const organizationRefs = userData.organizationsId || [];
  
        // Resolve organization references
        const organizationPromises = organizationRefs.map(async (orgRef) => {
          const orgSnapshot = await getDoc(orgRef);
          if (orgSnapshot.exists()) {
            const orgData = orgSnapshot.data();
            // Add document ID to organization data
            return { id: orgSnapshot.id, ...orgData };
          } else {
            return null;
          }
        });
  
        // Wait for all organization promises to resolve
        const organizations = await Promise.all(organizationPromises);
  
        console.log("User Organizations:", organizations);
        // You can dispatch organizations or return them as needed
        return organizations.filter(org => org !== null);
      } else {
        console.log("User not found");
        return []; // Return empty array if user document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user organizations:", error);
      return []; // Return empty array in case of error
    }
};


  return (
    <div className="login-container">
      {!showRegister ? (
        <form onSubmit={handleLogin}>
          <h2>Login</h2>
          <input
            type="email"
            placeholder="email"
            value={email} // Add value attribute
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            value={password} // Add value attribute
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
          <button type="button" onClick={() => setShowRegister(!showRegister)}>
            Register
          </button>

          {error && <span>Wrong email or password!</span>}
        </form>
      ) : (
        <Register setShowRegister={setShowRegister} />
      )}
    </div>
  );
};

export default Login;
