import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
import { useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import {
  doc,
  getDoc,
  updateDoc, // Import updateDoc for editing functionality
  onSnapshot,
  query,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

import {
  ingredientInputs,
  doughInputs,
  productInputs,
  userInputs,
  ingredientVolumeInputs, // Import volume inputs
  lineInputs,
  productionListInputs,
} from "../../formSource";

import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const Single = () => {
  const { type, objectId } = useParams();
  // console.log("Inside Single Page: type & ID:  ", type, objectId);
  const [ingredients, setIngredients] = useState([]);
  const [doughs, setDoughs] = useState([]);
  const [products, setProducts] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [conversionRates, setConversionRates] = useState([]);

  const [minMixUnit, setMinMixUnit] = useState(MASS_UNITS[0])
  const [maxMixUnit, setMaxMixUnit] = useState(MASS_UNITS[0])

  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const formConfig = {
    ingredients: { inputs: ingredientInputs, title: "Ingredient Information" },
    doughs: { inputs: doughInputs, title: "Dough Information" },
    products: { inputs: productInputs, title: "Product Information" },
    users: { inputs: userInputs, title: "User Information" },
    lines: { inputs: lineInputs, title: "Production Line Information" },
    productionlists: {
      inputs: productionListInputs,
      title: "Production List Information",
    },
  };

  const [documentData, setDocumentData] = useState(null); // State to hold document data
  const [editMode, setEditMode] = useState(false); // State to toggle edit mode
  const [editedData, setEditedData] = useState(null); // State to hold edited data

  const fetchItems = (collectionName, stateUpdater) => {
    return onSnapshot(
      query(
        collection(db, collectionName),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: collectionName + "/" + doc.id,
          collection: collectionName,
          ...doc.data(),
        }));
        stateUpdater(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const unsubscribeIngredients = fetchItems("ingredients", setIngredients);
    const unsubscribeDoughs = fetchItems("doughs", setDoughs);
    const unsubscribeProducts = fetchItems("products", setProducts);
    const unsubscribeLines = fetchItems("lines", setLines);
    const fetchData = async () => {
      const docRef = doc(db, type, objectId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDocumentData(docSnap.data()); // Update state with document data
        //setEditedData(docSnap.data()); // Initialize edited data with document data
      } else {
        console.log("No such document!");
      }
    };

    fetchData(); // Call the async function to fetch document data
    return () => {
      unsubscribeIngredients();
      unsubscribeDoughs();
      unsubscribeProducts();
      unsubscribeLines();
    };
  }, [type, objectId, organizationId]); // Run effect when type or objectId changes

  const allItems = [...ingredients, ...doughs, ...products];
  const allLines = [...lines];

  //Handle edit mode initialization
  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
    setEditedData(documentData);
    // Update selectedUnits for each ingredient in editedData.ingredients
    if (documentData.ingredients) {
      const updatedSelectedUnits = documentData.ingredients.map(
        (ingredient) => ({
          isVolume: false,
          unit: MASS_UNITS[0],
        })
      );
      setSelectedUnits(updatedSelectedUnits);

      const updatedConversionRates = documentData.ingredients.map(
        (ingredient) => {
          const selectedIngredient = allItems.find(
            (item) => item.id === Object.keys(ingredient)[0]
          );
          return selectedIngredient?.massconversion || null;
        }
      );
      setConversionRates(updatedConversionRates);
    }
  };
  //BASIC FORM INFO CHANGE HANDLING
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    console.log("editing name ", name, " value ", value);
    setEditedData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //HANDLE ADD FUNCTIONS - Ingredients - to recipes, Products - to lines, Lines & Products to Production Lists.
  const handleAddIngredient = () => {
    const newEditedIngredients = [...editedData.ingredients, { "": 0 }];
    const newSelectedUnits = [
      ...selectedUnits,
      {
        isVolume: false,
        unit: MASS_UNITS[0],
      },
    ];

    const newConversionRates = [...conversionRates, null];
    setEditedData({
      ...editedData,
      ingredients: newEditedIngredients,
    });

    setSelectedUnits(newSelectedUnits);
    setConversionRates(newConversionRates);
  };

  const handleAddProduct = () => {
    const newEditedProducts = [...editedData.products, { "": 0 }];

    setEditedData({
      ...editedData,
      products: newEditedProducts,
    });
  };

  const handleAddProductUnit = () => {
    const newEditedProductUnitss = [...editedData.productUnits, { name: "", pieceCount: 0 }];
    setEditedData({
      ...editedData,
      productUnits: newEditedProductUnitss,
    });
  }

  const handleAddLine = () => {
    const newEditedLines = [...editedData.linesAndProducts, { "": [] }];

    setEditedData({
      ...editedData,
      linesAndProducts: newEditedLines,
    });
  };

  const handleAddProductToLine = (lineIndex, lineRef) => {
    const newEditedLines = [...editedData.linesAndProducts];
    let lineData = newEditedLines[lineIndex];
    lineData[lineRef].push("");
    newEditedLines[lineIndex] = lineData;
    setEditedData({
      ...editedData,
      linesAndProducts: newEditedLines,
    });
  };

  //HANDLE SELECTS - Ingredients, Units, Lines, Products in Lines
  const handleIngredientSelectChange = (e, index) => {
    const { value } = e.target;
    const selectedIngredient = allItems.find(
      (ingredient) => ingredient.id === value
    );
    const newIngredients = [...editedData.ingredients];
    newIngredients[index] = {
      [selectedIngredient.id]: "",
    };
    setEditedData((prevData) => ({ ...prevData, ingredients: newIngredients }));

    const isVolume = false;
    if (isVolume) {
      const newConversionRates = [...conversionRates];
      newConversionRates[index] = selectedIngredient.massconversion;
      setConversionRates(newConversionRates);
    }
    const defaultUnit = MASS_UNITS[0];
    const newSelectedUnits = [...selectedUnits];
    newSelectedUnits[index] = { unit: defaultUnit, isVolume };
    setSelectedUnits(newSelectedUnits);
  };

  const handleProductSelectChange = (e, index) => {
    const { value } = e.target;
    const selectedProduct = allItems.find(
      (ingredient) => ingredient.id === value
    );
    const newProducts = [...editedData.products];
    newProducts[index] = {
      [selectedProduct.id]: "",
    };
    setEditedData((prevData) => ({ ...prevData, products: newProducts }));
  };

  const handleProductChange = (index, key, e) => {
    const { name, value } = e.target;
    console.log("name ", name, " value ", value);
    const newProducts = [...editedData.products];
    newProducts[index][key] = {
      ...newProducts[index][key],
      [name]: value,
    };
    setEditedData({ ...editedData, products: newProducts });
  };

  const handleLineSelectChange = (e, index) => {
    const { value } = e.target;
    const selectedLine = lines.find((line) => line.id === value);
    const newLines = [...editedData.linesAndProducts];
    newLines[index] = {
      [selectedLine.id]: [],
    };
    setEditedData((prevData) => ({ ...prevData, linesAndProducts: newLines }));
  };

  const handleProductLineSelectChange = (
    e,
    lineIndex,
    lineRef,
    productIndex
  ) => {
    const selectedProductId = e.target.value;
    const newEditedLines = [...editedData.linesAndProducts];
    let lineData = newEditedLines[lineIndex];

    if (
      lineData &&
      lineData[lineRef] &&
      lineData[lineRef].length > productIndex
    ) {
      lineData[lineRef][productIndex] = selectedProductId;
    }
    newEditedLines[lineIndex] = lineData;

    setEditedData({
      ...editedData,
      linesAndProducts: newEditedLines,
    });
  };

  const handleMixUnitChange = (e) => {
    const { value, id } = e.target;
    console.log("id ", id)
    const unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
    if (id === "minMix") {
      setMinMixUnit(unit);
    } else {
      setMaxMixUnit(unit)
    }
  };

  //HANDLE INPUT CHANGES 
  const handleMixInput = (e) => {
    let mixValue = 0
    const { id, value } = e.target;
    if (id === "minMix") {
      mixValue = value * minMixUnit.amount
    } else {
      mixValue = value * maxMixUnit.amount
    }

    setEditedData((prevData) => ({ ...prevData, [id]: mixValue }));

  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...editedData.ingredients];
    if (selectedUnits[index].isVolume) {
      newIngredients[index][key] =
        value * selectedUnits[index].unit.amount * conversionRates[index];
    } else {
      newIngredients[index][key] = value * selectedUnits[index].unit.amount;
    }
    setEditedData({ ...editedData, ingredients: newIngredients });
  };

  const handleUnitChange = (e, index) => {
    const { value } = e.target;

    let isVolume = false;
    let unit = VOLUME_UNITS.find((unit) => unit.abbreviation === value);

    if (!unit) {
      unit = MASS_UNITS.find((unit) => unit.abbreviation === value);
      isVolume = false;
    } else {
      isVolume = true;
    }

    if (unit) {
      const newSelectedUnits = [...selectedUnits];
      newSelectedUnits[index] = { unit, isVolume };
      setSelectedUnits(newSelectedUnits);
    }
  };

  const handleProductUnitChange = (index, value, caseOfEvent) => {
    console.log("index, ", index, value, caseOfEvent)
    let productUnits = editedData.productUnits
    if (caseOfEvent === "name") {
      productUnits[index].name = value
    }
    if (caseOfEvent === "count") {
      productUnits[index].pieceCount = value
    }
    setEditedData((prevData) => ({
      ...prevData,
      productUnits: productUnits
    }))
  }

  //HANDLE REMOVES
  const handleRemoveItem = (index, caseOfEvent, subIndex) => {
    console.log("index ", index, " caseOfEvent ", caseOfEvent);
    if (caseOfEvent === "ingredients") {
      const newIngredients = [...editedData.ingredients];
      newIngredients.splice(index, 1);
      setEditedData((prevData) => ({
        ...prevData,
        ingredients: newIngredients,
      }));

      const newSelectedUnits = [...selectedUnits];
      newSelectedUnits.splice(index, 1);
      setSelectedUnits(newSelectedUnits);

      const newConversionRates = [...conversionRates];
      newConversionRates.splice(index, 1);
      setConversionRates(newConversionRates);
    }
    if (caseOfEvent === "products") {
      const newProducts = [...editedData.products];
      newProducts.splice(index, 1);
      setEditedData((prevData) => ({ ...prevData, products: newProducts }));
    }
    if (caseOfEvent === "lines") {
      const newLines = [...editedData.linesAndProducts];
      newLines.splice(index, 1);
      setEditedData((prevData) => ({
        ...prevData,
        linesAndProducts: newLines,
      }));
    }
    if (caseOfEvent === "lineProducts") {
      const newLines = editedData.linesAndProducts.map((line) => ({ ...line }));
      const lineId = Object.keys(newLines[index])[0];
      const updatedProducts = newLines[index][lineId].filter(
        (_, idx) => idx !== subIndex
      );
      newLines[index][lineId] = updatedProducts;

      setEditedData((prevData) => ({
        ...prevData,
        linesAndProducts: newLines,
      }));
    }
    if (caseOfEvent === "productUnits") {
      const newProductUnits = [...editedData.productUnits];
      newProductUnits.splice(index, 1);
      setEditedData((prevData) => ({ ...prevData, productUnits: newProductUnits }));
    }
  };

  //Functions to find corresponding data.
  const getItemNameById = (id) => {
    //console.log("getting: ", id);
    const item = allItems.find((item) => item.id === id);
    return item ? item.name : "Unknown";
  };
  const getItemById = (id) => {
    //console.log("getting: ", id);
    const item = allItems.find((item) => item.id === id);
    return item ? item : "Unknown";
  };

  const getLineById = (id) => {
    //console.log("getting: ", id);
    const item = allLines.find((item) => item.id === id);
    return item ? item : "Unknown";
  };
  const getItemHydrationById = (id) => {
    //console.log("getting: ", id);
    const item = allItems.find((item) => item.id === id);
    return item.hydration ? item.hydration : 0;
  };
  const getTotalValue = (ingredients) => {
    return ingredients.reduce((sum, ingredientMap) => {
      return sum + Object.values(ingredientMap)[0];
    }, 0);
  };
  const getTotalHydration = (ingredients) => {
    let totalHydration = 0;
    let totalDry = 0;

    ingredients.forEach((ingredientMap) => {
      const ingredientId = Object.keys(ingredientMap)[0];
      const ingredientAmount = Object.values(ingredientMap)[0];
      const hydrationPercentage = getItemHydrationById(ingredientId);

      totalHydration += (hydrationPercentage / 100) * ingredientAmount;
      totalDry += (1 - hydrationPercentage / 100) * ingredientAmount;
    });
    return (totalHydration / totalDry) * 100;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const docRef = doc(db, type, objectId);
    await updateDoc(docRef, editedData); // Update document in Firestore
    setDocumentData(editedData);
    setEditMode(false); // Disable edit mode after saving
  };

  return (
    <div className="single">
      {/* {console.log("allItems ", allItems)} */}
      {/* {console.log(" documentData ", documentData)} */}
      {/* {console.log("editedData ", editedData)} */}
      {/* {console.log("selectedUnits ", selectedUnits)}
      {console.log("conversionRates ", conversionRates)} */}
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={handleEdit}>
              Edit
            </div>
            <h1 className="title">{formConfig[type].title}</h1>
            <div className="item">
              <img src="" alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">{documentData?.name}</h1>
                {/* Render form fields in edit mode */}
                {editMode ? (
                  <form onSubmit={handleSave}>
                    {formConfig[type].inputs.map((input, index) => (
                      <div key={index} className="detailItem">
                        <span className="itemKey">{input.label}:</span>
                        <input
                          onChange={handleChange}
                          type={input.type}
                          name={input.id}
                          value={
                            input.type === "checkbox"
                              ? undefined
                              : editedData
                                ? editedData[input.id]
                                : ""
                          }
                          checked={
                            input.type === "checkbox"
                              ? editedData[input.id]
                              : undefined
                          }
                        />
                      </div>
                    ))}
                    {editedData && editedData.volume ? (
                      <>
                        {ingredientVolumeInputs.map((input, index) => (
                          <div key={index} className="detailItem">
                            <span className="itemKey">{input.label}:</span>
                            <input
                              onChange={handleChange}
                              type={input.type}
                              name={input.id}
                              value={
                                input.type === "checkbox"
                                  ? undefined
                                  : editedData
                                    ? editedData[input.id]
                                    : ""
                              }
                              checked={
                                input.type === "checkbox"
                                  ? editedData[input.id]
                                  : undefined
                              }
                            />
                          </div>
                        ))}
                      </>
                    ) : null}

                    {editedData && editedData.minMix &&
                      <div className="detailItem">
                        <span className="itemKey">Min Mix</span>
                        <input
                          id="minMix"
                          onChange={handleMixInput}
                          type="Number"
                          name="minMix"
                          value={editedData["minMix"] / minMixUnit.amount}
                        />
                        <select
                          id="minMix"
                          onChange={(e) => {
                            handleMixUnitChange(e);
                          }}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      </div>
                    }
                    {editedData && editedData.maxMix &&
                      <div className="detailItem">
                        <span className="itemKey">Max Mix</span>
                        <input
                          id="maxMix"
                          onChange={handleMixInput}
                          type="Number"
                          name="maxMix"
                          value={editedData["maxMix"] / maxMixUnit.amount}
                        />
                        <select
                          id="maxMix"
                          onChange={(e) => {
                            handleMixUnitChange(e);
                          }}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      </div>
                    }

                    <button type="submit">Save</button>
                    <button type="button" onClick={() => setEditMode(false)}>
                      Cancel Edit
                    </button>
                  </form>
                ) : (
                  <>
                    {documentData &&
                      formConfig[type].inputs.map((input, index) => (
                        <div key={index} className="detailItem">
                          <span className="itemKey">{input.label}:</span>
                          {/* Here, let's ensure that documentData[input.name] exists before accessing it */}
                          <span className="itemValue">
                            {documentData[input.id]
                              ? documentData[input.id].toString()
                              : "Empty"}
                          </span>
                        </div>
                      ))}

                    {documentData && documentData.minMix &&
                      <div className="detailItem">
                        <span className="itemKey">Min Mix</span>
                        {(documentData["minMix"] / minMixUnit.amount).toString()}
                        <select
                          id="minMix"
                          onChange={(e) => {
                            console.log("unit select");
                            handleMixUnitChange(e);
                          }}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      </div>
                    }
                    {documentData && documentData.maxMix &&
                      <div className="detailItem">
                        <span className="itemKey">Max Mix</span>
                        {(documentData["maxMix"] / maxMixUnit.amount).toString()}
                        <select
                          id="maxMix"
                          onChange={(e) => {
                            handleMixUnitChange(e);
                          }}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      </div>
                    }
                    <div>
                    </div>

                    {documentData && documentData.volume ? (
                      <>
                        {ingredientVolumeInputs.map((input, index) => (
                          <div key={"vol" + index} className="detailItem">
                            <span className="itemKey">{input.label}:</span>
                            <span className="itemValue">
                              {documentData[input.id]}
                            </span>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="right">
            <div className="left">
              {/* <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" /> */}
            </div>
            {/* <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" /> */}
          </div>
        </div>

        <div className="bottom">
          {editMode && editedData.ingredients ? (
            <>
              {" "}
              <h1 className="title">Recipe</h1>
              <ul>
                {editedData.ingredients.map((ingredientMap, index) => (
                  <li key={index}>
                    {Object.entries(ingredientMap).map(
                      ([ingredientRef, amount], innerIndex) => (
                        <Fragment key={innerIndex}>
                          <select
                            key={innerIndex}
                            value={ingredientRef} // Set the select's value to the current ingredient reference
                            onChange={(e) =>
                              handleIngredientSelectChange(e, index)
                            }
                          >
                            <option key={"Select"} value="">
                              Select an Ingredient
                            </option>
                            {allItems.map((ingredient, idx) => (
                              <option key={idx} value={ingredient.id}>
                                {ingredient.name} ({ingredient.collection})
                              </option>
                            ))}
                          </select>
                          {ingredientRef !== "" ? (
                            <>
                              <input
                                type="number"
                                minimum={0.0001}
                                value={
                                  selectedUnits[index].isVolume
                                    ? (amount *
                                      selectedUnits[index].unit.amount) /
                                    conversionRates[index]
                                    : amount / selectedUnits[index].unit.amount
                                }
                                onChange={(e) =>
                                  handleIngredientChange(
                                    index,
                                    ingredientRef,
                                    e.target.value
                                  )
                                }
                              />
                              {allItems.find(
                                (item) => item.id === ingredientRef
                              ).volume ? (
                                <>
                                  <select
                                    onChange={(e) => {
                                      console.log("unit select");
                                      handleUnitChange(e, index);
                                    }}
                                  >
                                    {MASS_UNITS.map((unit, index) => (
                                      <option
                                        key={index}
                                        value={unit.abbreviation}
                                      >
                                        {unit.abbreviation}
                                      </option>
                                    ))}
                                    {VOLUME_UNITS.map((unit, index) => (
                                      <option
                                        key={index}
                                        value={unit.abbreviation}
                                      >
                                        {unit.abbreviation}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <select
                                    onChange={(e) => {
                                      console.log("unit select");
                                      handleUnitChange(e, index);
                                    }}
                                  >
                                    {MASS_UNITS.map((unit, index) => (
                                      <option
                                        key={index}
                                        value={unit.abbreviation}
                                      >
                                        {unit.abbreviation}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}{" "}
                            </>
                          ) : null}
                        </Fragment>
                      )
                    )}
                    <button
                      type="button"
                      onClick={() => handleRemoveItem(index, "ingredients")}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
              <button type="button" onClick={handleAddIngredient}>
                Add Ingredient
              </button>
            </>
          ) : (
            <>
              {documentData && documentData.ingredients ? (
                <>
                  {" "}
                  <h1 className="title">Recipe</h1>
                  <table>
                    <thead>
                      <tr>
                        <th>Ingredient </th>
                        <th>Quantity </th>
                        {type === "doughs" ? <th>Hydration </th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {documentData.ingredients.map((ingredientMap, index) => (
                        <tr key={index}>
                          {Object.entries(ingredientMap).map(
                            ([ingredientRef, amount], innerIndex) => (
                              <Fragment key={innerIndex}>
                                <td key={innerIndex}>
                                  {getItemNameById(ingredientRef)}
                                </td>
                                <td>
                                  {amount / MASS_UNITS[0].amount}{" "}
                                  {MASS_UNITS[0].abbreviation}
                                </td>
                                {type === "doughs" ? (
                                  <td>
                                    {getItemHydrationById(ingredientRef)} %
                                  </td>
                                ) : null}
                              </Fragment>
                            )
                          )}
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          {(
                            getTotalValue(documentData.ingredients) /
                            MASS_UNITS[0].amount
                          ).toFixed(2)}{" "}
                          {MASS_UNITS[0].abbreviation}
                        </td>
                        {type === "doughs" ? (
                          <td>
                            {getTotalHydration(
                              documentData.ingredients
                            ).toFixed(2)}{" "}
                            %
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : null}
            </>
          )}

          {editMode && editedData.productUnits ? (
            <>
              <h1 className="title">Product Units</h1>
              <ul>
                {editedData.productUnits.map((productUnitMap, index) => (
                  <li key={index}>
                    <Fragment key={index}>
                      {productUnitMap !== "" ? (
                        <>
                          <input
                            type="string"
                            value={productUnitMap.name}
                            onChange={(e) =>
                              handleProductUnitChange(index, e.target.value, "name")
                            }
                          />
                          <input
                            type="number"
                            minimum={0.5}
                            value={productUnitMap.pieceCount}
                            onChange={(e) =>
                              handleProductUnitChange(index, e.target.value, "count")
                            }
                          />
                        </>
                      ) : null}
                    </Fragment>

                    <button
                      type="button"
                      onClick={() => handleRemoveItem(index, "productUnits")}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
              <button type="button" onClick={handleAddProductUnit}>
                Add Unit
              </button>
            </>
          ) : (
            <>
              {documentData && documentData.productUnits ? (
                <>
                  {" "}
                  <h1 className="title">Product Units </h1>
                  <table>
                    <thead>
                      <tr>
                        <th>Unit </th>
                        <th>Count </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentData.productUnits.map(
                        (productUnitstMap, index) => (
                          <tr key={index}>
                            <Fragment key={index}>
                              <td key={index}>{productUnitstMap.name}</td>
                              <td>{productUnitstMap.pieceCount}</td>
                            </Fragment>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              ) : null}
            </>
          )}

          {editMode && editedData.products ? (
            <>
              <h1 className="title">Products</h1>
              {editedData.products.length > 0 ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>piecesPerHour</th>
                        <th>Scrap Factor %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {editedData.products.map((productMap, index) => (
                        <tr key={index}>
                          {Object.entries(productMap).map(
                            (
                              [productRef, { piecesPerHour, scrapPercent }],
                              innerIndex
                            ) => (
                              <Fragment key={innerIndex}>
                                <td>
                                  <select
                                    key={innerIndex}
                                    value={productRef} // Set the select's value to the current ingredient reference
                                    onChange={(e) =>
                                      handleProductSelectChange(e, index)
                                    }
                                  >
                                    <option key={"Select"} value="">
                                      Select an Product
                                    </option>
                                    {products.map((product, idx) => (
                                      <option key={idx} value={product.id}>
                                        {product.name} ({product.collection})
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                {productRef !== "" ? (
                                  <>
                                    <td>
                                      <input
                                        type="number"
                                        minimum={0.0001}
                                        name="piecesPerHour"
                                        value={piecesPerHour}
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            productRef,
                                            e
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        name="scrapPercent"
                                        minimum={0.0001}
                                        value={scrapPercent}
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            productRef,
                                            e
                                          )
                                        }
                                      />
                                    </td>
                                  </>
                                ) : null}
                              </Fragment>
                            )
                          )}
                          <td>
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveItem(index, "products")
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
              <button type="button" onClick={handleAddProduct}>
                Add Product
              </button>
              {
                //</div>
              }
            </>
          ) : (
            <>
              {documentData &&
                documentData.products &&
                documentData.products.length > 0 ? (
                <>
                  {" "}
                  <h1 className="title">Products</h1>
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Pieces/Hour</th>
                        <th>Scrap %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentData.products.map((productMap, index) => (
                        <tr key={index}>
                          {Object.entries(productMap).map(
                            (
                              [productRef, { piecesPerHour, scrapPercent }],
                              innerIndex
                            ) => (
                              <React.Fragment key={innerIndex}>
                                <td>{`${getItemNameById(productRef)}: `}</td>
                                <td>{`${piecesPerHour}`}</td>
                                <td>{`${scrapPercent}%`}</td>
                              </React.Fragment>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <ul>
                    {documentData.products.map((productMap, index) => (
                      <li key={index}>
                        {Object.entries(productMap).map(
                          ([productRef, {runtime, scrapPercent}], innerIndex) => (
                            <div key={innerIndex}>
                              {`${getItemNameById(productRef)}: ${runtime} ${scrapPercent}%`}
                            </div>
                          )
                        )}
                      </li>
                    ))}
                  </ul> */}
                </>
              ) : null}
            </>
          )}
          {editMode && editedData.linesAndProducts ? (
            <>
              <h1 className="title">Production Lines & Products</h1>
              <button type="button" onClick={handleAddLine}>
                Add Line
              </button>
              {editedData.linesAndProducts.map((line, lineIndex) => (
                <div key={lineIndex}>
                  {Object.entries(line).map(([lineRef, products]) => (
                    <div key={lineRef}>
                      {console.log("line & lineRef ", line, lineRef)}
                      <h4>Line {lineIndex + 1}: </h4>
                      {/* {getLineNameById(lineRef)} */}
                      <select
                        key={lineIndex}
                        value={lineRef} // Set the select's value to the current ingredient reference
                        onChange={(e) => {
                          handleLineSelectChange(e, lineIndex);
                        }}
                      >
                        <option key={"Select"} value="">
                          Select an Ingredient
                        </option>
                        {lines.map((line, idx) => (
                          <option key={idx} value={line.id}>
                            {line.name} ({line.collection})
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={(e) =>
                          handleAddProductToLine(lineIndex, lineRef)
                        }
                      >
                        Add Product
                      </button>
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(lineIndex, "lines")}
                      >
                        Remove
                      </button>
                      {products.map((product, productIndex) => (
                        <div key={productIndex}>
                          <ul>
                            <select
                              key={lineIndex}
                              value={product} // Set the select's value to the current ingredient reference
                              onChange={(e) => {
                                console.log("product ", product);
                                handleProductLineSelectChange(
                                  e,
                                  lineIndex,
                                  lineRef,
                                  productIndex
                                );

                                // handleLineSelectChange(e, lineIndex)
                              }}
                            >
                              <option key={"Select"} value="">
                                Select an Product
                              </option>
                              {console.log("line[lineRef] ", line[lineRef])}
                              {getLineById(lineRef).products.map(
                                (LineProduct, idx) => (
                                  <option
                                    key={idx}
                                    value={Object.keys(LineProduct)[0]}
                                  >
                                    {getItemNameById(
                                      Object.keys(LineProduct)[0]
                                    )}{" "}
                                    {console.log(
                                      "LineProduct ",
                                      Object.keys(LineProduct)[0]
                                    )}
                                  </option>
                                )
                              )}
                            </select>
                            {getItemNameById(product)} Product{" "}
                            {productIndex + 1}
                            <button
                              type="button"
                              onClick={() =>
                                handleRemoveItem(
                                  lineIndex,
                                  "lineProducts",
                                  productIndex
                                )
                              }
                            >
                              Remove
                            </button>
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <>
              {documentData &&
                documentData.linesAndProducts &&
                documentData.linesAndProducts.length > 0 ? (
                <>
                  <h1 className="title">Production Lines & Products</h1>
                  {documentData.linesAndProducts.map((line, lineIndex) => (
                    <div key={lineIndex}>
                      {Object.entries(line).map(([lineRef, products]) => (
                        <div key={lineRef}>
                          <h4>Line {lineIndex + 1}: </h4>
                          {getLineById(lineRef).name}
                          {products.map((product, productIndex) => (
                            <div key={product}>
                              <ul>
                                {" "}
                                {getItemNameById(product)} Product{" "}
                                {productIndex + 1}
                              </ul>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>
        {/* <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />

        </div> */}
      </div>
    </div>
  );
};
export default Single;
