import "./navbar.scss";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import React, { useState, useContext } from "react";

import { OrganizationsContext } from "../../context/OrganizationsContext";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../firebase"; // Assuming you have Firebase configuration set up
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);
  //console.log(currentUser);
  const {
    organizations,
    selected,
    dispatch: organizationsDispatch,
  } = useContext(OrganizationsContext);
  const [newOrgName, setNewOrgName] = useState("");
  const [showInput, setShowInput] = useState(false);

  const handleShowInputClick = () => {
    setShowInput(!showInput);
  };
  const handleInputChange = (event) => {
    setNewOrgName(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit: ", newOrgName);
    try {
      // Create a new document in the 'organizations' collection
      const organizationRef = await addDoc(collection(db, "organizations"), {
        name: newOrgName,
        timeStamp: serverTimestamp(),
      });

      // Get the ID of the newly created organization
      const organizationId = organizationRef.id;
      console.log(
        "currentUser.id ",
        currentUser.uid,
        " organizationRef ",
        organizationRef
      );
      const userRef = doc(db, "users", currentUser.uid);

      // Set the "capital" field of the city 'DC'
      //await updateDoc(doc(db, "users", currentUser.uid),{})

      await updateDoc(userRef, {
        organizationsId: arrayUnion(organizationRef),
      });
      // Update the 'users' collection to include the new organization's ID in the 'organizationsId' array
      // After updating the users collection, dispatch the action to add the new organization
      organizationsDispatch({
        type: "ADD_ORGANIZATION",
        payload: { id: organizationId, name: newOrgName },
      });

      // Reset the organization name input field
      setNewOrgName("");
      setShowInput(false);
      // Optionally, you can add a success message or perform other actions upon successful submission
      console.log("Organization created successfully!");
    } catch (error) {
      console.error("Error creating organization:", error);
      // Handle errors here
    }
  };

  const handleOrganizationChange = (event) => {
    const selectedOrgId = event.target.value;
    const selectedOrg = organizations.find((org) => org.id === selectedOrgId);
    // Dispatch action to update selected organization with name and id
    organizationsDispatch({
      type: "SELECT_ORGANIZATION",
      payload: {
        id: selectedOrgId,
        name: selectedOrg.name,
      },
    });
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="">
          {//console.log("organizations ", organizations)
          }
          {organizations && (
            <select
              value={selected ? selected.id : ""}
              onChange={handleOrganizationChange}
            >
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name} {/* Assuming organization has a "name" property */}
                </option>
              ))}
            </select>
          )}
          <button onClick={handleShowInputClick}>+</button>
          {showInput && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={newOrgName}
                onChange={handleInputChange}
              />
              <button type="submit">Submit</button>
              <button type="button" onClick={handleShowInputClick}>
                Cancel
              </button>
            </form>
          )}
          {/* 
          <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon /> 
          </div>*/}
        </div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
