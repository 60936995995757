import React from 'react';
import "./printableComponent.css";

const LabelsPrintableComponent = React.forwardRef(({ summaryData, day, date, generateMixerChartTable }, ref) => {
  return (
    <div ref={ref} className="printableComponent printable-container">

      {summaryData.map((list, listIndex) => (
        <div key={listIndex}>
          {list.lines.map((line, lineIndex) => (
            <div key={lineIndex}>
              {line.products.map((product, productIndex) => (
                <div key={productIndex}>
                  <p>
                    {product.ingredientDetails.map((ingredient, ingredientIndex) => {
                      const fullMixes = Math.floor(ingredient.mixes);
                      const remainingMix = ingredient.mixes - fullMixes;
                      const remainingMixValue = remainingMix > 0
                        ? Math.max(ingredient.mixMin, remainingMix * ingredient.mixMax)
                        : 0;

                      return (
                        <React.Fragment key={ingredientIndex}>
                          {Array.from({length: ingredient.mixTimes.length}, (_, i)=> (<>
                        
                            List: {list.listName} {day} {date} <br/>
                            Line: {line.lineName} <br/>
                            {console.log("ingredient ",ingredient)}
                            Dough: {ingredient.ingredientName} ({ingredient.ingredientCode})<br/>
                            <table>
                            <th>Mix </th><th>Repose </th><th>Run </th>
                            <tr>
                              <td>
                              {ingredient.mixTimes[i].startMixTime} 
                              </td>
                              <td>
                              {ingredient.mixTimes[i].startReposeTime}
                              </td>
                              <td>
                              {ingredient.mixTimes[i].startTime}
                              </td>
                            </tr>
                            </table>

                            {/* Mix Time:{ingredient.mixTimes[i].startMixTime} {" "} 2nd Mix:{ingredient.mixTimes[i].secondMixTime} {" "} Repose Time: {ingredient.mixTimes[i].startReposeTime} {" "} <br/>
                            Run Time:{ingredient.mixTimes[i].startTime} {" "} */}
                            {i < fullMixes ?
                            <div className="page-break"></div>
                            : null}
                            </>
                          ))}

                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
      
    </div>
  );
});

export default LabelsPrintableComponent;
